import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  Typography,
} from "@mui/material"
import { LocationService, useLocation } from "./LocationService"
import React, { useEffect, useMemo, useState } from "react"

import { CloseIcon } from "@chakra-ui/icons"
import { MenuItem } from "@material-ui/core"
import { auth } from "../firebase"
import { getLocationStr } from "../utils"
import { styled } from "@mui/material/styles"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "130vw",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

const rootStyle: any = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "baseline",
}

let setFromStore = false

export const UserLocation = function UserLocation(props: any) {
  const {
    style,
    isNoLink: isNotLink,
    size,
    textPosition,
    textStyle,
    iconColor,
    iconStyle,
    hideIcon,
    center,
    viewOnly,
    color,
    fullWidth,
  } = props
  const $styles = [$container, style]
  const $iconStyles = [$icon, iconStyle]

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<any>("")
  const [selectedProvince, setSelectedProvince] = useState<any>("")
  const [selectedCity, setSelectedCity] = useState<any>("")
  const [provinces, setProvinces] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [persistSelection, setPersistSelection] = useState(false)
  const [userSelectedFilter, setUserSelectedFilter] = useState<any>({
    country: "US",
  })
  // const [availableLocations, setAvailableLocations] = useState<any>({})
  const [user] = useAuthState(auth)

  const { availableLocations } = useLocation()
  const { t } = useTranslation()

  const { user: userFromStore } = userStore

  useEffect(() => {
    if (!setFromStore && userFromStore) {
      setFromStore = true
      const obj = {
        country: userFromStore.userAddress?.country || selectedCountry || "US",
        province: userFromStore.userAddress?.province || selectedProvince || "",
        city: userFromStore.userAddress?.city || selectedCity || "",
      }
      setSelectedProvince(obj.province)
      setSelectedCountry(obj.country)
      setSelectedCity(obj.city)

      setUserSelectedFilter(obj)
    }
  }, [selectedCity, selectedCountry, selectedProvince, userFromStore])

  useMemo(() => {
    const loc: any = userSelectedFilter || {}

    // console.log("LOC----1--->", loc)

    if (loc.city) {
      setSelectedCity(loc.city)
    }
    if (loc.province) {
      setSelectedProvince(loc.province)
    }
    if (loc.country) {
      setSelectedCountry(loc.country)
    }
  }, [userSelectedFilter])

  useEffect(() => {
    const obj = {
      province: selectedProvince,
      city: selectedCity,
      country: selectedCountry,
    }
    LocationService.setLocation(obj)
    setUserSelectedFilter(obj)
  }, [selectedCity, selectedCountry, selectedProvince])

  // useEffect(() => {
  //   if (userSelectedFilter) {
  //     setCities(citiesDropdown(userSelectedFilter, selectedCountry, selectedProvince, t))
  //   }
  // }, [selectedCountry, selectedProvince])

  useEffect(() => {
    setSelectedProvince("")
    setSelectedCity("")
    LocationService.setLocation({
      province: "",
      city: "",
      country: selectedCountry,
    })
  }, [selectedCountry])

  useEffect(() => {
    setSelectedCity("")
    LocationService.setLocation({
      province: selectedProvince,
      city: "",
      country: selectedCountry,
    })
  }, [selectedCountry, selectedProvince])

  useEffect(() => {
    setSelectedCity("")
    LocationService.setLocation({
      province: selectedProvince,
      city: "",
      country: selectedCountry,
    })
  }, [selectedProvince, selectedCountry])

  useEffect(() => {
    setSelectedCity("")
    setSelectedProvince("")
    LocationService.setLocation({
      province: "",
      city: "",
      country: selectedCountry,
    })
  }, [selectedCountry])

  // const screen = useScreenDimensions();
  const { location } = useLocation()

  function userLocationText(): any {
    const loc = {
      ...location,
      ...userSelectedFilter,
    }

    loc.country = t((loc.country || "us").toLocaleLowerCase())

    return <Typography fontSize={"smaller"}>{getLocationStr(loc)}</Typography>
  }

  // console.log("mappedAvailableLocation----->", JSON.stringify(countriesDropdown))
  // console.log("provincesDropdown----->", JSON.stringify(provincesDropdown(selectedCountry)))
  // console.log(
  //   "citiesDropdown----->",
  //   JSON.stringify(citiesDropdown(selectedCountry, selectedProvince)),
  // )

  // console.log("LocationService---->", {
  //   selectedCountry,
  //   selectedProvince,
  //   selectedCity,
  // })

  const ViewOnly = () => {
    return (
      <Button
        variant="contained"
        color={color || "success"}
        fullWidth={fullWidth}
        onClick={() => {
          setModalVisible(true)
        }}
      >
        {textPosition !== "RIGHT" && userLocationText()}
        {hideIcon ? null : <LocationIcon style={$iconStyles} color={iconColor} size={size || 20} />}
        {textPosition === "RIGHT" && userLocationText()}
      </Button>
    )
  }

  if (viewOnly) {
    return <ViewOnly />
  }

  // return <>availableLocations:{JSON.stringify(availableLocations)}</>
  return (
    <div>
      <BootstrapDialog
        open={modalVisible}
        // animationType="fade"
        // transparent={true}
        // visible={modalVisible}
        // onRequestClose={() => {
        //   setModalVisible(!modalVisible);
        // }}
      >
        <div style={rootStyle}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Select Location
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setModalVisible(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent
          dividers
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <FormControl fullWidth>
            <InputLabel>{t("country")}</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedCountry}
              label="Country"
              onChange={(val) => {
                const country: any = val?.target?.value || "US"
                setSelectedCountry(country)
                setUserSelectedFilter((val: any) => ({ ...val, country }))
              }}
            >
              {Object.keys(availableLocations).map((country: any, i: number) => (
                <MenuItem
                  key={`${country}-${i}`}
                  value={country}
                  style={{
                    textTransform: "uppercase",
                  }}
                >
                  {t((country || "").toLocaleLowerCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>State</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedProvince}
              label="State"
              onChange={(val) => {
                const province: any = val?.target?.value || ""
                setSelectedProvince(province)
                setUserSelectedFilter((val: any) => ({ ...val, province }))
              }}
            >
              <MenuItem value={""}>{"Any"}</MenuItem>

              {Object.keys(availableLocations?.[selectedCountry] || {})?.map(
                (city: any, i: number) => (
                  <MenuItem key={`${city}-${i}`} value={city}>
                    {city}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>City</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedCity}
              label="City"
              onChange={(val) => {
                setSelectedCity(val?.target?.value || "")
              }}
            >
              <MenuItem value={""}>{"Any"}</MenuItem>

              {availableLocations?.[selectedCountry]?.[selectedProvince]?.map(
                (city: any, i: number) => (
                  <MenuItem key={`${city}-${i}`} value={city}>
                    {city}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={() => {}}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>

      <ViewOnly />
    </div>
  )
}

export function LocationIcon(props: any) {
  const { size, style, color } = props
  const $iconStyles = [$icon, style]
  return null
  // <Icon
  //   name="map-marked-alt"
  //   isFont
  //   isFa5
  //   size={size || 20}
  //   style={$iconStyles}
  //   color={color}
  // />
}

const $container = {
  justifyContent: "center",
  flexDirection: "row",
}

const $text = {
  // fontFamily: typography.primary.normal,
  fontSize: 14,
  // color: colors.palette.primary500,
  marginHorizontal: 5,
}

const $icon = {
  marginLeft: 1,
  // transform: [{ rotateZ: "90deg" }],
}
const $textField = {
  margin: 10,
}

const $mr5 = { marginRight: 5 }

// const AnimatedListbox = React.forwardRef(function AnimatedListbox(
//   props: MenuListboxSlotProps,
//   ref: React.ForwardedRef<HTMLUListElement>,
// ) {
//   const { ownerState, ...other } = props;
//   const popupContext = React.useContext(PopupContext);

//   if (popupContext == null) {
//     throw new Error(
//       'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
//     );
//   }

//   const verticalPlacement = popupContext.placement.split('-')[0];

//   return (
//     <CssTransition
//       className={`placement-${verticalPlacement}`}
//       enterClassName="open"
//       exitClassName="closed"
//     >
//       <Listbox {...other} ref={ref} />
//     </CssTransition>
//   );
// });
