import { Button, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material"
import { Favorite, FavoriteBorder } from "@mui/icons-material"
import React, { useEffect, useState } from "react"
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  or,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import { auth, db } from "../../firebase"

import Carousel from "react-material-ui-carousel"
import { Image } from "@fluentui/react"
import { LoadingService } from "../../LoadingService"
import { PopupService } from "../PopupService"
import { formatCash } from "../../utils";
import htmr from "htmr"
import styled from "styled-components"
import { useAuthState } from "react-firebase-hooks/auth"
import { useLocation } from "../LocationService"
import { useTranslation } from "react-i18next"

const UnorderedList = styled.ul`
  list-style: disc !important;
  margin: 0 16px !important;
  padding-left: 40px !important;
`
// const Paragraph =
//   (num: number): any =>
//   (props: any): any => {
//     const { children } = props
//     return <Typography variant={`h${num || 1}` as any}>{children}</Typography>
//   }
const transform = {
  ul: UnorderedList,
  h1: styled.h1`
    font-weight: 900 !important;
    text-align: center !important;
    font-size: xx-large !important;
  `,
  h2: styled.h2`
    font-weight: 800 !important;
    text-align: center !important;
    font-size: x-large !important;
  `,
  h3: styled.h3`
    font-weight: 700 !important;
    text-align: center !important;
    font-size: larger !important;
  `,
  h4: styled.h4`
    font-weight: 600 !important;
    text-align: center !important;
    font-size: large !important;
  `,
  h5: styled.h5`
    font-weight: 500 !important;
    text-align: center !important;
    font-size: medium !important;
  `,
  h6: styled.h6`
    font-weight: 400 !important;
    text-align: center !important;
    font-size: small !important;
  `,
}

const listenForRaffleConfigChanges = function (setData: any) {
  LoadingService.setLoading(true)
  const querySnapshot = doc(db, "app-configurations/display-content-config")

  return onSnapshot(querySnapshot, {
    next: (data) => {
      setData(data.data())
      LoadingService.setLoading(false)
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true)
    },
    complete: () => {
      LoadingService.setLoading(false)
    },
  })
}

const listenForRafflesDataChanges = function (setData: any, location: any) {
  LoadingService.setLoading(true)
  console.log("listenForRafflesDataChanges---location--->", location)
  let arr: any[] = [
    and(
      where("location.country", "==", location?.country || "US"),
      where("raffleDate", ">=", Date.now()),
    ),
  ]

  location?.province &&
    (arr = [
      and(
        or(
          where("location.province", "==", location.province),
          where("location.province", "==", "*"),
        ),
        and(
          where("location.country", "==", location.country || "US"),
          where("raffleDate", ">=", Date.now()),
        ),
      ),
    ])

  location?.city &&
    (arr = [
      and(
        or(where("location.city", "==", location.city), where("location.city", "==", "*")),
        where("location.country", "==", location.country || "US"),
        where("location.country", "==", location.province),
        where("raffleDate", ">=", Date.now()),
      ),
    ])

  const querySnapshot = query(collection(db, "raffles"), ...arr)

  return onSnapshot(querySnapshot, {
    next: (snap) => {
      const arr: any[] = []
      snap.forEach((data) => arr.push(data.data()))
      setData(arr)
      LoadingService.setLoading(false)
    },
    error: (data) => {
      PopupService.pushPopup(data?.message || "Error loading data.", "Error!")
      LoadingService.setLoading(false)
    },
    complete: () => {
      LoadingService.setLoading(false)
    },
  })
}

export default function Raffles() {
  const [loading, setLoading] = useState()
  const [user] = useAuthState(auth)
  const { t } = useTranslation()

  const [raffles, setRaffles] = useState<any[]>([])
  const [rafflesConfig, setRafflesConfig] = useState({
    descriptionLength: 50,
    hideAllActions: true,
    imageResizeMode: "contain",
    rafflesImageHeight: 500,
  })

  const { location } = useLocation()

  useEffect(() => {
    return listenForRaffleConfigChanges(setRafflesConfig)
  }, [location])

  useEffect(() => {
    return listenForRafflesDataChanges(setRaffles, location)
  }, [location])

  const handleLike = async (raffleId: string) => {
    if (!user) {
      return
    }

    const likeRef = doc(db, `raffles/${raffleId}/likes`, user.uid)
    const likeDoc = await getDoc(likeRef)

    if (likeDoc.exists()) {
      await updateDoc(likeRef, { liked: !likeDoc.data().liked })
    } else {
      await setDoc(likeRef, { liked: true })
    }

    setRaffles(
      raffles.map((raffle: any) =>
        raffle.id === raffleId ? { ...raffle, liked: !raffle.liked } : raffle,
      ),
    )
  }

  if (!raffles?.length) {
    return null
  }

  return (
    <div
      style={{
        marginTop: 10,
        // width: "90%",
        // display: "flex",
        // flexDirection: "column",
        // height: "100vh",
      }}
    >
      {raffles.map((raffle: any, i: number) => (
        <div
          onDoubleClick={() => handleLike(raffle.id)}
          key={`${raffle.name}-${i}`}
          style={{
            borderWidth: 1,
            borderColor: "#DDD",
            borderStyle: "solid",
            marginTop: 10,
            marginBottom: 10,
            padding: 10,
          }}
        >
          <Typography variant="h4">{raffle.title}</Typography>
          <Typography variant="h5">{raffle.subtitle}</Typography>
          <Typography variant="h6">{raffle.description}</Typography>
          <Carousel
            cycleNavigation
            fullHeightHover
            indicators
            navButtonsAlwaysVisible={false}
            // height={400}
            autoPlay
            animation="slide"
            // duration={500}
            swipe
            interval={4000}
            stopAutoPlayOnHover
          >
            {raffle.raffleImages.map((item: string, i: number) => (
              <div
                key={i}
                style={{
                  // borderColor: "red",
                  // borderWidth: 1,
                  // borderStyle: "double",

                  height: rafflesConfig.rafflesImageHeight,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <img
                  src={item}
                  style={{
                    height: "50vh",
                    width: "90%",
                    objectFit: "contain",
                  }}
                />
              </div>
            ))}
          </Carousel>
          <Typography>{new Date(raffle.raffleDate).toString()}</Typography>
          <div className="flex row gap-5 space-between justify-center align-center items-center">
            <Like handleLike={handleLike} raffle={raffle} />
            <Button
              startIcon={<Image src={require("../../assets/icons/termsIcon.png")} width={30} />}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                // PopupService.pushPopup(raffle.termsText)
                PopupService.pushPopup(htmr(raffle.termsText, { transform }))
              }}
              // sx={{ mt: 2 }}
            >
              {t("terms")}
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}

function Like(props: { handleLike: (raffleId: string) => Promise<void>; raffle: any }) {
  const { handleLike, raffle } = props
  // const { t } = useTranslation()
  const [user] = useAuthState(auth)
  const [liked, setLiked] = useState<boolean>(false)
  const [likeCount, setLikeCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const fetchLikes = async (likesRef: any) => {
    if (!user) {
      return
    }
    setLoading(true)
    try {
      const likeDoc = await getDoc(doc(likesRef, user.uid))

      setLiked(likeDoc.exists() ? likeDoc.data().liked : false)
    } catch (error: any) {
      LoadingService.setLoading(true, error?.message || error, true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!raffle) {
      return
    }

    const likesRef = collection(db, `raffles/${raffle.id}/likes`)
    const likesQuery = query(likesRef, where("liked", "==", true))

    fetchLikes(likesRef)

    return onSnapshot(likesQuery, {
      next: async (snap) => {
        const likeCount = snap.size
        setLikeCount(likeCount)

        if (likeCount === 0) {
          setLiked(false)
        } else {
          try {
            if (!user) {
              return
            }
            const likeDoc = await getDoc(doc(likesRef, user.uid))

            setLiked(likeDoc.exists() ? likeDoc.data().liked : false)
          } catch (error: any) {
            LoadingService.setLoading(true, error?.message || error, true)
          } finally {
            setLoading(false)
          }
        }

        LoadingService.setLoading(false)
      },
      error: (data) => {
        LoadingService.setLoading(true, data?.message || data, true)
      },
      complete: () => {
        LoadingService.setLoading(false)
      },
    })
  }, [raffle, user])

  return (
    <Stack direction="row" spacing={0.1} alignItems="center">
      {/* <Typography variant="caption">{t("like")}</Typography> */}

      <IconButton onClick={loading ? undefined : () => handleLike(raffle.id)}>
        {loading ? (
          <CircularProgress size={24} />
        ) : liked ? (
          <Favorite color="error" />
        ) : (
          <FavoriteBorder />
        )}
      </IconButton>

      <Typography variant="body2">{formatCash(likeCount)}</Typography>
    </Stack>
  )
}

function Item(props: any) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">Check it out!</Button>
    </Paper>
  )
}
