import {
  Button,
  ButtonOwnProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material"
import React, { createContext, useContext, useEffect, useState } from "react"

import { CloseIcon } from "@chakra-ui/icons"

export const PopupService = {
  pushPopup: (popupVal: any, titleVal?: string | null, style?: any) =>
    console.log("PopupService--pushPopup-->", popupVal),
  setPopup: (...args: any) => console.log("PopupService--setPopup-->", args),
  getPopup: () => console.log("getPopup---->"),
  setTitle: (title: string) => console.log("PopupService--title-->", title),
}

const PopupContext = createContext({ popup: null, title: "" })

export const usePopup = () => {
  return useContext(PopupContext)
}
let popupCount = 1
const BootstrapDialog = styled(Dialog)(({ theme, style }) => {
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  return {
    "& .MuiPaper-root": {
      width: "100%",
      margin: matches ? 30 : 5,
      height: style?.height,
      marginRight: matches ? 60 : 0,
      maxWidth: matches ? 600 : "100%",

      ...style,
    },
  }
})

export const PopupProvider = (props: { children: any; defaultPopup?: any }) => {
  const { children, defaultPopup = null } = props
  const [popup, setPopup] = useState<any>(defaultPopup)

  const [title, setTitle] = useState("")
  useEffect(() => {
    PopupService.setPopup = (
      val: any,
      titleVal?: string | { [key: string]: any },
      style?: any,
      onClosed?: () => void,
    ) => {
      const styleVal = style ? style : typeof titleVal !== "string" ? {} : titleVal
      setPopup([
        <ModalPopup
          key={popupCount}
          title={typeof titleVal === "string" ? titleVal : "" || title}
          popup={val}
          styleValue={styleVal}
          onClosed={onClosed}
        />,
      ])
    }
    PopupService.getPopup = () => popup
    PopupService.setTitle = setTitle
    PopupService.pushPopup = (popupVal: any, titleVal?: string | null, style?: any) => {
      setPopup((val: any) => {
        const key = popupCount++

        return [
          ...(Array.isArray(val) ? val : [val]),
          <ModalPopup
            style={style}
            key={`${key}-${titleVal || ""}`}
            title={titleVal || title}
            popup={popupVal}
          />,
        ]
      })
    }
  }, [])
  // return null
  return (
    <PopupContext.Provider value={{ popup, title }}>
      {children}

      {popup}
    </PopupContext.Provider>
  )
}

export function ModalPopup(props: {
  title: string
  popup: any
  type?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
  style?: any
  styleValue?: any
  showOk?: boolean
  onClosed?: () => void
}) {
  const { title, popup, type, style, showOk, styleValue, onClosed } = props
  const [modalVisible, setModalVisible] = useState(true)

  useEffect(() => {
    setModalVisible(true)
  }, [popup])

  const styleValProp = typeof styleValue === "string" ? {} : styleValue
  const className = typeof styleValue === "string" ? styleValue : ""
  // const classes = className.split(" ").reduce((prev, cur) => {
  //   return {
  //     [cur]: cur,
  //     ...prev,
  //   }
  // }, {})

  return (
    <BootstrapDialog
      open={modalVisible}
      style={styleValProp || style}
      className={className}
      onClose={onClosed}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClosed && onClosed()
            setModalVisible(false)
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={style}>
        {React.Children.map(popup, (child, key) => {
          if (React.isValidElement(child)) {
            const popupProps: any = { close: () => setModalVisible(false), key }
            return React.cloneElement(child, popupProps)
          }
          return child
        })}
        {!!showOk && (
          <Button
            fullWidth
            size="medium"
            variant="outlined"
            color={type}
            onClick={() => setModalVisible(false)}
          >
            OK
          </Button>
        )}
      </DialogContent>
    </BootstrapDialog>
  )
}
