import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "./index.css"
import "./router.css"
import "./App.css"
import "@fortawesome/react-fontawesome"
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Button, CssBaseline, ThemeProvider } from "@mui/material"
import { Loading, LoadingProvider } from "./Loading"
import React, { useEffect, useState } from "react"

import App from "./App"
import { AuthProvider } from "./AuthProvider"
import ErrorPage from "./error-page"
import { LoadingService } from "./LoadingService"
import { LocationProvider } from "./components/LocationService"
import { PopupProvider } from "./components/PopupService"
import { Provider } from "./MuiProvider"
import ReactDOM from "react-dom/client"
import { Routes } from "./routes"
// import Root from "./root"
import { auth } from "./firebase"
import { createTheme } from "@mui/material/styles"
import reportWebVitals from "./reportWebVitals"
import { signInAnonymously } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App />,
//     errorElement: <ErrorPage />,
//     children: routes
//       .filter((x) => {
//         if (x.auth && !user) {
//           return false
//         }
//         return true
//       })
//       .map(({ name, element }) => {
//         return {
//           path: name,
//           element,
//         }
//       }),
//   },
// ])
// const Guard = (): any => {
//   const [user] = useAuthState(auth)
//   const [router, setRouter] = useState<any>()

//   useEffect(() => {
//     ;(async () => {
//       try {
//         if (!user) {
//           LoadingService.setLoading(true)
//           signInAnonymously(auth)
//         }
//       } catch (error: any) {
//         LoadingService.setLoading(true, error?.message || error, true)
//       } finally {
//         LoadingService.setLoading(false)
//       }
//     })()
//   }, [user])

//   useEffect(() => {
//     const _router = createBrowserRouter([
//       {
//         path: "/",
//         element: <App />,
//         errorElement: <ErrorPage />,
//         children: routes
//           .filter((x) => {
//             if (x.auth && !user) {
//               return false
//             }
//             return true
//           })
//           .map(({ name, element }) => {
//             return {
//               path: name,
//               element,
//             }
//           }),
//       },
//     ])
//     setRouter(_router)
//   }, [user])

//   return !router ? null : (

//   )
// }

const theme = createTheme({
  palette: {
    mode: "light", // or 'dark'
  },
})
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<div id="__t">
  <Provider theme={theme}>
    <React.StrictMode>
      <CssBaseline />
      <LoadingProvider>
        <AuthProvider partial>
          <LocationProvider>
            <PopupProvider>
              {/* <Guard /> */}
              {/* <RouterProvider router={router} fallbackElement={<Loading />}></RouterProvider> */}
              <Routes />
            </PopupProvider>
          </LocationProvider>
        </AuthProvider>
      </LoadingProvider>
    </React.StrictMode>
  </Provider></div>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
