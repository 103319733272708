// import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, PhoneAuthProvider, TwitterAuthProvider, browserPopupRedirectResolver, browserSessionPersistence, getAuth, initializeAuth } from 'firebase/auth';

import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// import { auth as firbaseuiAuth } from 'firebaseui';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};




// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
// export const auth = initializeAuth(app, {
//   persistence: browserSessionPersistence,
//   popupRedirectResolver: browserPopupRedirectResolver,
// });
export const auth = getAuth(app);

export const observeAuthState = (callback) => {
  onAuthStateChanged(auth, callback);
};

// const ui = new firbaseuiAuth.AuthUI(auth);

// const facebookProvider = new FacebookAuthProvider();
// facebookProvider.addScope('email');
// facebookProvider.setCustomParameters({
//   'display': 'popup'
// });

// export const initFirebaseUI = () => ui.start('#firebaseui-auth-container', {
//   signInOptions: [
//     // EmailAuthProvider.PROVIDER_ID,
//     {
//       provider: GoogleAuthProvider.PROVIDER_ID,
//       requireDisplayName: true,
//       popupMode: true
//     }
//     // { provider: facebookProvider }
//     // FacebookAuthProvider.PROVIDER_ID,
//     // PhoneAuthProvider.PROVIDER_ID,

//     // TwitterAuthProvider.PROVIDER_ID,
//     // auth.GithubAuthProvider.PROVIDER_ID
//     // 
//   ],
//   // Other config options...
// });