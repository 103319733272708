
export const LoadingService = {
  hideLoading: (
    message
  ) => console.log('showConfirmDialog---->', message),
  showLoading: (
    message
  ) => console.log('showConfirmDialog---->', message),
  setLoading: (loading, message, okToDismiss) => console.log('showConfirmDialog---->', { loading, message, okToDismiss }),
};
