import React, { createContext, useContext, useEffect, useState } from "react"
import { auth, db } from "../firebase"
import { doc, getDoc } from "firebase/firestore"

import { LoadingService } from "../LoadingService"
import { useAuthState } from "react-firebase-hooks/auth"

export const LocationService = {
  setLocation: (location: any) => console.log("LocationService--location-->", location),
  getLocation: () => console.log("getLocation---->"),
  setAvailableLocations: (val: any) => console.log("setAvailableLocations---->"),
}

export interface ICountry {
  currency: string
  disabled: boolean
  display: string
  icon: string
  key: string
}
export interface ILocation {
  [key: string]: {
    [key: string]: string[]
  }
}
export const DEFAULT_COUNTRY = {
  currency: "USD",
  disabled: false,
  display: "United States",
  icon: "https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/assets%2Fus-flag.jpeg?alt=media&token=b86edf51-856a-458f-b491-8f05bd979e7e",
  key: "US",
}

export const DEFAULT_AVAILABLE_LOCATION = {
  US: {
    Florida: ["Orlando"],
    "New York": ["Queens"],
  },
}

const val: {
  location: string
  availableLocations: ILocation
} = {
  location: DEFAULT_COUNTRY.key,
  availableLocations: DEFAULT_AVAILABLE_LOCATION,
}

const LocationContext = createContext(val)

export const useLocation = (): any => {
  return useContext(LocationContext)
}

const getAvailableLocations = async (setData: any) => {
  LoadingService.setLoading(true)
  try {
    const querySnapshot = doc(db, "app-configurations/availableLocations")

    const ref = await getDoc(querySnapshot)
    const data = ref.data()
    setData && setData(data)
    return data
  } catch (error) {
    return {}
  } finally {
    LoadingService.setLoading(false)
  }
}

export const LocationProvider = (props: { children: any; defaultLocation?: any }) => {
  const { children, defaultLocation = DEFAULT_AVAILABLE_LOCATION } = props
  const [location, setLocation] = useState<string>(defaultLocation)
  const [availableLocations, setAvailableLocations] = useState<ILocation>(
    DEFAULT_AVAILABLE_LOCATION,
  )
  const [user] = useAuthState(auth)

  useEffect(() => {
    LocationService.setLocation = setLocation
    LocationService.setAvailableLocations = setAvailableLocations
    LocationService.getLocation = () => location
    user && getAvailableLocations(setAvailableLocations)
  }, [user])

  return (
    <LocationContext.Provider value={{ location, availableLocations }}>
      {children}
    </LocationContext.Provider>
  )
}
