import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables"
import React, { useEffect, useState } from "react"
import { and, collection, getDocs, query, where } from "firebase/firestore"
import { auth, db } from "../firebase"

import { DateTime } from "luxon"
import { Loading } from "../Loading"
import { LoadingService } from "../LoadingService"
import { PopupService } from "./PopupService"
import TransferCodeCard from "./TransferCodeCard"
import { colors } from "../utils/colors"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"

export interface Ticket {
  qrCodeDataUrl?: string //base 64 qr code
  code: string
  createdBy: string
  createdDate: number
  design: Design
  disabled: boolean
  expirationDate: number
  id: string
  index: number
  isSold: boolean
  isWinner: boolean
  order: number
  payerUID: string
  points: number
  raffleId: string
  raffleTicketId: string
  raffleType: string
  ticketSellerUID: string
  value: number
}

export interface Design {
  backgroundColor: string
  color: string
  height: number
  image: string
  justifyContent: string
  margin: number
  padding: number
  textColor: string
  width: number
}

const TestTicket = {
  code: "123456",
  createdBy: "uid",
  createdDate: 1719621689470,
  design: {
    backgroundColor: "#FDB000",
    color: "#000",
    height: 120,
    image: "",
    justifyContent: "center",
    margin: 5,
    padding: 5,
    textColor: "color",
    width: 180,
  },
  disabled: false,
  expirationDate: 1727717443000,
  id: "uid",
  index: 0,
  isSold: false,
  isWinner: false,
  order: 0,
  payerUID: "uid",
  points: 0,
  raffleId: "id",
  raffleTicketId: "id",
  raffleType: "str",
  ticketSellerUID: "sellerUID",
  value: 0,
}

const Tickets: React.FC = () => {
  const { t } = useTranslation()
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [raffles, setRaffles] = useState<any>([])
  const [raffleIds, setRaffleIds] = useState<any>([])
  const [selectedRaffleId, setSelectedRaffleId] = useState<any>("")
  const [user] = useAuthState(auth)

  useEffect(() => {
    const fetchTickets = async () => {
      if (!user && !selectedRaffleId) {
        return
      }

      try {
        LoadingService.setLoading(true)
        const ticketsCollection = collection(db, `users/${user?.uid}/tickets`)
        const ticketSnapshot = await getDocs(
          query(ticketsCollection, where("expirationDate", ">=", DateTime.now().toMillis())),
        )
        const ticketList: Ticket[] = ticketSnapshot.docs.map((doc) => {
          const data = doc.data() as Ticket
          setRaffleIds((arr: any[]) =>
            arr.find((x) => x === data.raffleId) ? arr : [...arr, data.raffleId],
          )
          return data
        })

        setTickets(ticketList)
      } catch (error) {
      } finally {
        LoadingService.setLoading(false)
      }
    }

    fetchTickets()
  }, [selectedRaffleId, user])

  useEffect(() => {
    if (raffleIds?.length) {
      const fetchRaffles = async () => {
        if (!user) {
          return
        }

        try {
          LoadingService.setLoading(true)
          const col = collection(db, `raffles`)
          const snap = await getDocs(query(col, where("id", "in", raffleIds)))
          const list: any[] = snap.docs.map((doc) => {
            const data = doc.data() as any
            return data
          })

          setRaffles(list)
        } catch (error) {
        } finally {
          LoadingService.setLoading(false)
        }
      }

      fetchRaffles()
    }
  }, [raffleIds, user])

  useEffect(() => {
    const fetchTickets = async () => {
      if (!user || !selectedRaffleId) {
        return
      }
      try {
        LoadingService.setLoading(true)

        const ticketsCollection = collection(db, `users/${user?.uid}/tickets`)
        const ticketSnapshot = await getDocs(
          query(
            ticketsCollection,
            and(
              where("raffleId", "==", selectedRaffleId),
              where("expirationDate", ">=", DateTime.now().toMillis()),
            ),
          ),
        )
        const ticketList: Ticket[] = ticketSnapshot.docs.map((doc) => {
          const data = doc.data() as Ticket
          setRaffleIds((arr: any[]) =>
            arr.find((x) => x === data.raffleId) ? arr : [...arr, data.raffleId],
          )
          return data
        })

        setTickets(ticketList)
      } catch (error) {
      } finally {
        LoadingService.setLoading(false)
      }
    }

    fetchTickets()
  }, [selectedRaffleId, user])

  // const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilterText(e.target.value)
  // }

  // const handleClearFilter = () => {
  //   setFilterText("")
  // }

  if (!tickets?.length) {
    return null //<Loading />
  }

  // const filteredTickets = tickets.filter(
  //   (ticket) => ticket.code.includes(filterText) || ticket.createdBy.includes(filterText),
  // )

  return (
    <div>
      <div className="flex mb-5">
        {/* raffles: {JSON.stringify(raffles)} */}
        <FormControl fullWidth>
          <InputLabel>{t("Select Raffle")}</InputLabel>
          <Select
            className="flex flex-column gap-10"
            value={selectedRaffleId}
            label={t("Select Raffle")}
            onChange={(val) => {
              const raffleId: any = val?.target?.value || ""
              setSelectedRaffleId(raffleId)
            }}
          >
            <MenuItem value="">
              <em>{t("All")}</em>
            </MenuItem>
            {raffles.map((raffle: any, i: number) => (
              <MenuItem
                key={`${raffle}-${i}`}
                value={raffle.id}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {`${raffle.title}${raffle.subtitle ? ` - ${raffle.subtitle}` : ""}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        className="flex flex-row flex-wrap gap-5  align-center justify-center"
        style={{ zoom: 0.8 }}
      >
        {tickets?.map((item, i) => {
          return (
            <TicketItem
              key={`${item.id}-${i}`}
              onUpdateQRCode={(qrCode: any) => {
                item.qrCodeDataUrl = qrCode
                setTickets(
                  tickets.map((ticket) => {
                    if (ticket.id === item.id) {
                      ticket.qrCodeDataUrl = qrCode
                    }
                    return ticket
                  }),
                )
              }}
              item={{
                ...item,
                design: {
                  ...item.design,
                  width: item.design.width,
                  height: item.design.height,
                  color: item.design.backgroundColor,
                  textColor: item.design.color,
                },
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Tickets

export function TicketItem(props: any) {
  const { item, onPress, onUpdateQRCode } = props
  const [design, setDesign] = useState({ ...item?.design, ...GetTicketDesign(item?.design) })
  const [textColor, setTextColor] = useState(item?.design?.textColor)

  useEffect(() => {
    // console.log("item.design----->", item?.design?.textColor)
    setDesign({ ...item?.design, ...GetTicketDesign(item?.design) })
    setTextColor(item?.design?.textColor)
  }, [item?.design])

  return (
    <div
      style={{
        width: design.width,
        height: design.height,
        color: textColor,
      }}
      onClick={() => {
        PopupService.pushPopup(<TransferCodeCard ticket={item} onUpdateQRCode={onUpdateQRCode} />)
      }}
    >
      <div
        style={{
          backgroundImage: `url(${design.image || require("./ticket-img-no-name.png")})`,
          backgroundRepeat: "no-repeat",
          padding: design.padding,
          // height: '100%',
          // backgroundSize: "contain"
          backgroundSize: "97% 100%",
        }}
      >
        <div
          style={{
            // display: "flex",
            ...$root(design.color),
            flexDirection: "column",
            ...$innerRoot(design),
            overflow: "hidden",
          }}
        >
          <Row
            hide={!item.code}
            textColor={textColor}
            labelTx="Code"
            value={item.code}
            style={$row}
            size="xs"
          />

          {!item.expirationDate ? null : (
            <Row
              textColor={textColor}
              labelTx="Date"
              value={getLuxonDate(+item.expirationDate, true).toFormat("D")}
              style={$row}
            />
          )}
          {!item.raffleType ? (
            <Text text="TICKET" size="xxl" style={$empty(textColor)} />
          ) : (
            <Row
              textColor={textColor}
              numberOfLines={2}
              labelTx="Raffle"
              value={item.raffleType}
              style={$row}
              valueSize="xxs"
            />
          )}

          {hide(
            <View style={$win}>
              <Text tx="win" preset="bold" size="md" style={$text} />
            </View>,
            item,
            "isWinner",
          )}
        </div>
      </div>{" "}
    </div>
  )
}

const View = ({ children, style }: any) => (
  <div style={{ flexDirection: "column", ...getStyle(style) }}>{children}</div>
)

export const Row = ({
  label,
  value,

  labelTx,
  valueTx,

  style,
  children,
  wrap,
  size,
  labelSize,
  valueSize,
  numberOfLines = 1,
  spaceBetween = false,
  onPress = null,
  leftContent = false,
  hide = false,
  valueStyle = null,
  labelStyle = null,
  textColor = null,
}: any) => {
  if (hide) {
    return null
  }
  const Parent: any = !onPress
    ? ({ children, style }: any) => (
        <div
          style={{
            ...getStyle(style),
            display: "flex",
            flexDirection: "row",
          }}
        >
          {children}
        </div>
      )
    : ({ children, style }: any) => <div style={getStyle(style)}>{children}</div>

  const Label = (
    <p style={{ lineHeight: 1 }}>
      <b>{labelTx || label}</b>
      {": "}
      <span style={{ fontSize: 13 }}>{valueTx || value}</span>
    </p>
    // <Text
    //   text={`${label}: `}
    //   tx={labelTx}
    //   size={labelSize || size}
    //   style={{ color: textColor, fontWeight: 'bold' }}
    // >
    //  {": "} {ValueText}
    // </Text>
  )
  return (
    <Parent style={[styles({ wrap, spaceBetween }), style]} onPress={onPress}>
      <div style={{ display: "flex", flexDirection: "row", height: 18 }}>{Label}</div>
      {leftContent ? null : !!children && children}
    </Parent>
  )
}

function getStyle(style: any) {
  return Array.isArray(style)
    ? style.reduce((c, n) => ({ ...c, ...n, display: "flex" }), {})
    : style
}

const styles = (props: any) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: props?.wrap ? "wrap" : "nowrap",
  justifyContent: props?.spaceBetween ? "space-between" : undefined,
})

const $sizeStyles: any = {
  xxl: { fontSize: 36 },
  xl: { fontSize: 24 },
  lg: { fontSize: 20 },
  md: { fontSize: 18 },
  sm: { fontSize: 16 },
  xs: { fontSize: 14 },
  xxs: { fontSize: 12 },
}
const Text = ({ children, tx, text, size, style, color, ...rest }: any) => (
  <p style={{ ...getStyle(style), color, ...$sizeStyles[size], ...styles(rest) }}>
    {tx || text}
    {children}
  </p>
)
const hide = (elem: any, item: any, prop: any) => (item[prop] ? elem : null)

const $win = {
  display: "flex",
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: colors.error,
  borderRadius: 10,
  // height: 30,
  padding: 2,
  justifyContent: "flex-start",
}
const $100p = {
  display: "flex",
  width: "100%",
  height: "100%",
}

const $row = {
  display: "flex",
  alignSelf: "flex-start",
}

const $root = (backgroundColor: any) => ({
  display: "flex",
  borderColor: "#000",
  borderWidth: 2,
  borderRadius: 10,
  padding: 2,
  // margin: 13,
  backgroundColor: backgroundColor || colors.palette.neutral100,
  width: "100%",
  // height: 100,
  justifyContent: "flex-start",
  borderStyle: "solid",
})

const $text = {
  display: "flex",
  color: "#36ab53",
  textAlign: "center",
}

function $empty(textColor: any) {
  return {
    display: "flex",
    alignSelf: "center",
    color: textColor,
  }
}

export const getLuxonDate = (date: any, isSeconds = false): any => {
  if (!date) {
    return console.log(`Invalid date (${date}).`)
  }
  let date_ = null
  if (isSeconds) {
    date_ = DateTime.fromMillis(date)
    date_ = date_.isValid ? date_ : DateTime.fromSeconds(date)
  } else {
    date_ = DateTime.fromISO(date)
    date_ = date_.isValid ? date_ : DateTime.fromJSDate(date)
  }
  return date_
}

function $innerRoot(design: any) {
  return {
    display: "flex",
    padding: 5,
    width: +design?.width - design?.padding - 10,
    height: +design?.height - design?.padding - 10,
  }
}

const DefaultTicketDesign = {
  width: 180,
  height: 120,
  margin: 5,
  padding: 5,
}

const GetTicketDesign = (obj: any, offset?: any, marginAndPadding?: any) => {
  const mAndA = marginAndPadding
    ? {}
    : {
        margin: +(obj?.margin || DefaultTicketDesign.margin) + (offset?.margin || 0),
        padding: +(obj?.padding || DefaultTicketDesign.padding) + (offset?.padding || 0),
      }
  return {
    width: +(obj?.width || DefaultTicketDesign.width) + (offset?.width || 0),
    height: +(obj?.height || DefaultTicketDesign.height) + (offset?.height || 0),
    ...mAndA,
  }
}
