// TODO: write documentation for colors and palette in own markdown file and add links from here

const palette = {
  neutral100: "#FFFFFF",
  neutral200: "#F4F2F1",
  neutral300: "#D7CEC9",
  neutral400: "#B6ACA6",
  neutral500: "#978F8A",
  neutral600: "#564E4A",
  neutral700: "#3C3836",
  neutral800: "#191015",
  neutral900: "#009A56",

  primary100: "#30C273",
  primary200: "#009A56",
  primary300: "#008457",
  primary400: "#006E54",
  primary500: "#00594C",
  primary600: "#004946",

  secondary100: "#C7F9CF",
  secondary200: "#92F4AC",
  secondary300: "#59E08A",
  secondary400: "#30C273",
  secondary500: "#009A56",

  accent100: "#FFEED4",
  accent200: "#FFE1B2",
  accent300: "#FDD495",
  accent400: "#FBC878",
  accent500: "#FFBB50",

  angry100: "#F2D6CD",
  angry500: "#C03403",

  overlay20: "rgba(25, 16, 21, 0.2)",
  overlay50: "rgba(25, 16, 21, 0.5)",

  "color-primary-100": "#C7F9CF",
  "color-primary-200": "#92F4AC",
  "color-primary-300": "#59E08A",
  "color-primary-400": "#30C273",
  "color-primary-500": "#009A56",
  "color-primary-600": "#008457",
  "color-primary-700": "#006E54",
  "color-primary-800": "#00594C",
  "color-primary-900": "#004946",
  "color-success-100": "#DCFBD6",
  "color-success-200": "#B3F7AE",
  "color-success-300": "#82E884",
  "color-success-400": "#5ED26C",
  "color-success-500": "#30B54D",
  "color-success-600": "#239B48",
  "color-success-700": "#188243",
  "color-success-800": "#0F683C",
  "color-success-900": "#095637",
  "color-info-100": "#D3E6FE",
  "color-info-200": "#A9CBFD",
  "color-info-300": "#7DACFA",
  "color-info-400": "#5C91F5",
  "color-info-500": "#2867EF",
  "color-info-600": "#1D4FCD",
  "color-info-700": "#143AAC",
  "color-info-800": "#0C288A",
  "color-info-900": "#071B72",
  "color-warning-100": "#FEFACB",
  "color-warning-200": "#FEF598",
  "color-warning-300": "#FDED65",
  "color-warning-400": "#FBE53E",
  "color-warning-500": "#F9D800",
  "color-warning-600": "#D6B600",
  "color-warning-700": "#B39600",
  "color-warning-800": "#907700",
  "color-warning-900": "#776100",
  "color-danger-100": "#FDDCD2",
  "color-danger-200": "#FCB2A7",
  "color-danger-300": "#F67E7A",
  "color-danger-400": "#ED575F",
  "color-danger-500": "#E22440",
  "color-danger-600": "#C21A42",
  "color-danger-700": "#A21242",
  "color-danger-800": "#830B3E",
  "color-danger-900": "#6C063B",
} as const

export const colors = {
  /**
   * The palette is available to use, but prefer using the name.
   * This is only included for rare, one-off cases. Try to use
   * semantic names as much as possible.
   */
  palette,
  /**
   * A helper for making something see-thru.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The default text color in many components.
   */
  text: palette.neutral700,
  /**
   * Secondary text information.
   */
  textDim: palette.neutral600,
  /**
   * The default color of the screen background.
   */
  background: palette.neutral200,
  /**
   * The default border color.
   */
  border: palette.neutral400,
  /**
   * The main tinting color.
   */
  tint: palette.primary500,
  /**
   * A subtle color used for lines.
   */
  separator: palette.neutral300,
  /**
   * Error messages.
   */
  error: palette.angry500,
  /**
   * Error Background.
   *
   */
  errorBackground: palette.angry100,
}
