import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"

import React from "react"
import { useTranslation } from "react-i18next"

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation()
  const currentLanguage = i18n.language || "en"

  const handleLanguageChange = (event: any) => {
    const selectedLanguage = (event.target.value as string) || "en"
    i18n.changeLanguage(selectedLanguage)
  }

  return (
    <Box sx={{ minWidth: 120, marginTop: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="language-selector-label">{t("profile.language")}</InputLabel>
        <Select
          className="flex flex-column gap-10"
          label={t("profile.language")}
          labelId="language-selector-label"
          value={currentLanguage}
          onChange={handleLanguageChange}
        >
          <MenuItem value="en">{t("English")}</MenuItem>
          <MenuItem value="es">{t("Español")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
/*
  <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              className="flex flex-column gap-10"
              value={selectedCountry}
              label="Country"
              onChange={(val) => {
                const country: any = val?.target?.value || "US"
                setSelectedCountry(country)
                setUserSelectedFilter((val: any) => ({ ...val, country }))
              }}
            >
              {Object.keys(availableLocations).map((country: any, i: number) => (
                <MenuItem key={`${country}-${i}`} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
*/
export default LanguageSelector
