import React, { ReactElement, useEffect, useState } from "react"

import { Button } from "@mui/material"
import { Text } from "@chakra-ui/layout"
import { parse } from "../../utils"
import { useTranslation } from "react-i18next"

export function AdItem(props: any): any {
  /*
  {"item":
  {
  "id":"SWC6e584pkXvy5GGS5Vl",
  "name":"Martinez De Jesus ",
  "link":"https://instagram.com/martinezdejesusimport?igshid=MzRlODBiNWFlZA==",
  "maxTime":6,"location":{"county":"","country":"DO",
  "street":"","city":"*","province":"*","postcode":""},
  "createdBy":"8jgEZQysdubaJQmJrh2f99GiPjs2","height":"",
  "txt":"",
  "createdDate":1701213841743,
  "img":"https://firebasestorage.googleapis.com:443/v0/b/winfacil-250ad.appspot.com/o/ads%2FSWC6e584pkXvy5GGS5Vl%2Fassets%2FMartinez%20De%20Jesus%20-img.jpg?alt=media&token=3bce3b3e-b7d2-4dbd-bd22-b436d3480ff3",
  "order":10,
  "disabled":false,
  "width":"100%"}}
  */
  const [txtStyle, setTxtStyle] = useState({})
  const [txt, setTxt] = useState(props.txt)
  const { id, name, link, maxTime, street, createdBy, img, order, disabled, maxWidth, maxHeight } =
    props

  useEffect(() => {
    setTxtStyle(parse(props.overlayText?.style, {}))
    setTxt(props.txt || props.overlayText?.text)
  }, [props])

  // const { t } = useTranslation()

  return (
    <Root
      link={link}
      style={{
        width: "100%",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {img ? (
        <>
          <img
            // resizeMode={resizeMode}
            // maxHeight={editMode ? 200 : null}
            style={{
              height: "25vh",
              objectFit: "contain",
              padding: 5,
              maxHeight,
              maxWidth,
            }}
            loading="lazy"
            src={img}
          />
          <Text style={txtStyle}>{txt}</Text>
        </>
      ) : (
        <Text style={txtStyle}>{txt}</Text>
      )}
    </Root>
  )
}

const Root = (props: { [x: string]: any; children: any; link: any }): React.JSX.Element => {
  const { children, link, ...rest } = props
  return link ? (
    <a href={link} target="_blank" {...rest}>
      {children}
    </a>
  ) : (
    <div {...rest}>{children}</div>
  )
}
