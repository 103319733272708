import React, { useEffect, useState } from "react"
import { and, collection, doc, onSnapshot, or, query, where } from "firebase/firestore"
import { auth, db } from "../firebase"
import { parse, useImagePreloader } from "../utils"
import { useParams, useSearchParams } from "react-router-dom"

import { AdItem } from "./Ads/AdItem"
import { Button } from "@mui/material"
import Carousel from "react-material-ui-carousel"
import { LoadingService } from "../LoadingService"
import { Logo } from "./Logo"
import { PopupService } from "./PopupService"
import { useAuthState } from "react-firebase-hooks/auth"
import { useLocation } from "./LocationService"

// import { useTranslation } from "react-i18next"

// import { useTranslation } from "react-i18next"
const DEFAULT_ADS_CONFIG: {
  width: string
  height: string
  style: string
  disabled: boolean
  secondsInterval: number
  viewLinkOnPopupIframe: boolean
  linkTarget?: string | undefined
  animation?: "slide" | "fade" | undefined
  stopAutoPlayOnHover?: boolean
  indicators?: boolean
  cycleNavigation?: boolean
  fullHeightHover?: boolean
  autoPlay?: boolean
  swipe?: boolean
} = {
  width: "100%",
  height: "100%",
  style: '{ borderColor: "#ddd", borderWidth: 1}',
  disabled: false,
  secondsInterval: 6,
  viewLinkOnPopupIframe: false,
  linkTarget: "_blank", // "_self" | "_blank"| "_parent"| "_top"
  animation: "slide", // slide | fade
  stopAutoPlayOnHover: true,
  indicators: false,
  cycleNavigation: true,
  fullHeightHover: true,
  autoPlay: true,
  swipe: true,
}
const listenForAdsConfigChanges = function (
  configLocation: string = "loc-001",
  businessId: string | undefined,
  setData: any,
) {
  if (!businessId) {
    return
  }
  LoadingService.setLoading(true)
  const querySnapshot = doc(
    db,
    "businesses",
    businessId,
    `config-${configLocation || "loc-001"}`,
    "ads",
  )

  return onSnapshot(querySnapshot, {
    next: (data) => {
      const resultData = { ...DEFAULT_ADS_CONFIG, ...data.data() }
      resultData.height = resultData.height.replace("%", "vh")
      //   resultData.width = resultData.width.replace("%", "vh")

      setData(resultData)
      LoadingService.setLoading(false)
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true)
    },
    complete: () => {
      LoadingService.setLoading(false)
    },
  })
}

const listenForAdsDataChanges = function (
  businessId: string | undefined,
  setData: any,
  location: any,
) {
  if (!businessId) {
    return PopupService.setPopup(
      <div className="flex flex-col justify-center align-center">
        <Logo />
        <h1>Missing business information.</h1>
        <h6>Please contact your administrator provider.</h6>
      </div>,
      "",
      {
        // display: "flex",
        // alignItems: "center !important",
        // justifyContent: "center !important",
        maxHeight: "calc(100% - 5px) !important",
        minHeight: "150px !important",
        margin: "0 !important",
      }, //"flex align-center justify-center max-h-fit m-0"
    )
  }

  LoadingService.setLoading(true)
  // console.log("listenForAdsDataChanges---location--->", location)
  let arr: any[] = [and(where("country", "==", location?.country || "US"))]

  location?.province &&
    (arr = [
      and(
        or(where("province", "==", location.province), where("province", "==", "*")),
        where("country", "==", location.country || "US"),
      ),
    ])

  location?.city &&
    (arr = [
      and(
        or(where("city", "==", location.city), where("city", "==", "*")),
        where("country", "==", location.country || "US"),
        where("country", "==", location.province),
      ),
    ])

  const querySnapshot = query(collection(db, "businesses", businessId, "ads"), ...arr)

  return onSnapshot(querySnapshot, {
    next: (snap) => {
      const arr: any[] = []
      snap.forEach((data) => arr.push(data.data()))
      setData(arr)
      LoadingService.setLoading(false)
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true)
    },
    complete: () => {
      LoadingService.setLoading(false)
    },
  })
}

export function BusinessAds(): any {
  const [adsConfig, setAdsConfig] = useState(DEFAULT_ADS_CONFIG)

  const [mainStyle, setMainStyle] = useState({})
  const [adsData, setAdsData] = useState([])
  const [user] = useAuthState(auth)
  const [openedPopup, setOpenedPopup] = useState(false)

  const { i: businessId } = useParams()
  const [searchParams] = useSearchParams()
  // useEffect(() => {}, [LocationService.getLocation()])
  const loc: any = searchParams.get("l")
  useEffect(() => {
    const style = parse(adsConfig?.style, {
      borderColor: "#ddd",
      borderWidth: 1,
    })
    setMainStyle({
      borderStyle: "solid",
      ...style,
      display: "flex",
      justifyContent: "center",
    })
  }, [adsConfig])

  const { imagesPreloaded } = useImagePreloader(adsData?.map((x: any) => x.image))
  const { location } = useLocation()

  useEffect(() => {
    if (user) {
      return listenForAdsConfigChanges(loc, businessId, setAdsConfig)
    }
  }, [location, user, businessId, loc])

  useEffect(() => {
    if (user) {
      return listenForAdsDataChanges(businessId, setAdsData, location)
    }
  }, [location, user, businessId])

  // const { t } = useTranslation()

  if (!imagesPreloaded) {
    return null
  }

  if (adsConfig?.disabled) {
    return null
  }

  if (!adsData?.length) {
    return null
  }

  const Link = adsConfig.viewLinkOnPopupIframe
    ? PopupIframe
    : ({ children, image, ...rest }: any) => {
        return image.viewLinkOnPopupIframe ? (
          <PopupIframe {...rest} image={image}>
            {children}
          </PopupIframe>
        ) : (
          <a href={image.link} target={image.linkTarget || adsConfig.linkTarget}>
            {children}
          </a>
        )
      }
  if (openedPopup) {
    return null
  }
  //   const is100 = /100%/.test(adsConfig.height)
  return (
    <div
      style={{
        ...mainStyle,
        width: adsConfig.width,
        height: adsConfig.height || "100%",
      }}
    >
      <Carousel
        cycleNavigation={adsConfig.cycleNavigation}
        fullHeightHover={adsConfig.fullHeightHover}
        indicators={adsConfig.indicators}
        navButtonsAlwaysVisible={false}
        autoPlay={adsConfig.autoPlay}
        swipe={adsConfig.swipe}
        animation={adsConfig.animation}
        interval={adsConfig.secondsInterval * 1000}
        stopAutoPlayOnHover={adsConfig.stopAutoPlayOnHover}
      >
        {adsData.map((item: any, i: number) => (
          <div key={i} style={parse(item.style, {})}>
            <Carousel
              cycleNavigation={adsConfig.cycleNavigation}
              fullHeightHover={adsConfig.fullHeightHover}
              indicators={adsConfig.indicators}
              navButtonsAlwaysVisible={false}
              autoPlay={adsConfig.autoPlay}
              // swipe={adsConfig.swipe}
              animation={item.animation || "fade"}
              interval={(adsConfig.secondsInterval * 1000) / item.images.length}
              stopAutoPlayOnHover={adsConfig.stopAutoPlayOnHover}
            >
              {item.images.map((image: any, j: number) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100vw",
                  }}
                >
                  <Link key={`${i}-${j}`} image={image} setOpenedPopup={setOpenedPopup}>
                    <img
                      src={image.image}
                      alt={`ad ${j}`}
                      style={{ height: adsConfig.height || "100vh" }}
                    />
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

const PopupIframe = ({ children, image, setOpenedPopup }: any) => {
  return (
    <Button
      onClick={() => {
        setOpenedPopup(true)
        PopupService.setPopup(
          <iframe title="title" src={image.link} className="flex justify-center w-full h-full" />,
          "",
          {
            maxHeight: "calc(100% - 5px) !important",
            minHeight: "150px !important",
            margin: "0 !important",
            height: "100% !important",
          },
          () => {
            setOpenedPopup(false)
          },
        )
      }}
    >
      {children}
    </Button>
  )
}
