import {
    AppGalleryButton,
    AppStoreButton,
    ButtonsContainer,
    GooglePlayButton,
} from "react-mobile-app-button";

import AndroidLogo from '../images/android-logo.svg';
import AppleLogo from '../images/apple-logo.svg';
import Button from '@mui/material/Button';
import { Download } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from "react";
import { Row } from 'react-bootstrap';
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function Logo(props) {
    const { width = 200, height, style } = props;
    return <img src={require('../images/logo.png')} style={style} width={width} height={height} alt="winfacil-logo" />;
}

export function DownloadLogo(props) {

    const { t } = useTranslation();
    const { width = 150, height, style } = props;
    return (<div style={{ position: 'relative', borderRadius: 90 }} className="flex overflow-hidden m-1">
        <img src={require('../assets/download-button.jpg')} style={{ minWidth: width, objectFit: 'contain', ...style }} width={width} height={height} alt="winfacil-download-logo" />
        <Typography style={{ textTransform: 'none', position: 'absolute', left: '34%', top: '28%' }}>{t('download')}</Typography>
    </div>);
}

export function AppleDownload(props) {
    const { width = 20, height } = props;
    return (<Row cols={2} >
        <img src={AppleLogo} width={width} height={height} alt="android-store-logo" />
        <Download />
    </Row>);
}

export function AndroidDownload(props) {
    const { width = 20, height } = props;
    return (<Row cols={2}>
        <img src={AndroidLogo} width={width} height={height} alt="android-store-logo" />
        <Download />
    </Row>);
}


const APKUrl = require('../app-release-0.54.0.apk');
const IOSUrl = "https://apps.apple.com/us/app/winfacil/id6443819589";

export function DownloadButtons() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                sx={{
                    fontSize: 15
                }}
                size="small" color="inherit" onClick={handleClick}>
                {/* <Logo style={{ width: 130, objectFit: "contain" }} /> */}
                <DownloadLogo />
                {/* <Download /> */}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                <MenuItem>
                    <AppStoreButton
                        url={IOSUrl}
                        theme={"light"}
                        className={"custom-style"}
                    />
                </MenuItem>
                {/* <MenuItem >
                    <GooglePlayButton
                        url={APKUrl}
                        theme={"light"}
                        className={"custom-style"}
                    />
                </MenuItem> */}
            </Menu>
        </div>
    );
}