import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"

import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"

function ServiceForm({ open, onClose, onSubmit, service }: any) {
  const { t } = useTranslation()
  const [title, setTitle] = useState(service?.title || "")
  const [description, setDescription] = useState(service?.description || "")
  const [linkUrl, setLinkUrl] = useState(service?.linkUrl || "")
  const [image, setImage] = useState(service?.image || "")
  const [imageFile, setImageFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(service?.image || "")

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(imageFile)
    } else {
      setImagePreview(image)
    }
  }, [imageFile, image])

  const handleImageChange = (e: { target: { files: React.SetStateAction<null>[] } }) => {
    setImageFile(e.target.files[0])
  }

  const handleSubmit = async () => {
    let imageUrl = image

    if (imageFile) {
      const storage = getStorage()
      const storageRef = ref(storage, `images/${uuidv4()}`)
      await uploadBytes(storageRef, imageFile)
      imageUrl = await getDownloadURL(storageRef)
    }

    onSubmit({ title, description, linkUrl, image: imageUrl })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{service ? t("editService") : t("addService")}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label={t("title")}
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("description")}
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          margin="dense"
          label={t("linkUrl")}
          type="text"
          fullWidth
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
        />
        <div className="mt-4">
          <input type="file" accept="image/*" onChange={handleImageChange as any} />
          {imagePreview && (
            <div className="mt-2">
              <img src={imagePreview} alt={t("Selected")} className="w-32 h-32 object-cover" />
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("cancel")}</Button>
        <Button onClick={handleSubmit} color="primary">
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ServiceForm
