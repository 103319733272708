import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { useCallback, useState } from "react"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"

import Cropper from "react-easy-crop"
import { getCroppedImg } from "../utils/cropImage"
import { observer } from "mobx-react-lite"
import { db as storage } from "../firebase"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"
import { v4 as uuidv4 } from "uuid"

interface EditProfileProps {
  onCancel: () => void
  onSave: (profile: any) => void
}

const EditProfile: React.FC<EditProfileProps> = observer(({ onCancel, onSave }) => {
  const { t } = useTranslation()
  const { user, updateUser } = userStore

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [updatedProfile, setUpdatedProfile] = useState(user)
  const [newImage, setNewImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState(user?.profileImageUrl)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [cropDialogOpen, setCropDialogOpen] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setUpdatedProfile((prevState: any) => ({ ...prevState, [name]: value }))
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0])
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      setCropDialogOpen(true)
    }
  }

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleSave = async () => {
    if (newImage && croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(imagePreview || '', croppedAreaPixels, zoom)
        const response = await fetch(croppedImage as string)
        const blob = await response.blob()
        const uniqueId = uuidv4()
        const file = new File([blob], `${uniqueId}-${newImage.name}`, { type: "image/jpeg" })

        const storageRef = ref(storage as any, `profileImages/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            console.error(error)
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            await updateUser({ ...updatedProfile, profileImageUrl: downloadURL })
          },
        )
      } catch (error) {
        console.error("Error cropping image:", error)
      }
    } else {
      await updateUser(updatedProfile)
    }
    onSave && onSave(updatedProfile)
  }

  const handleCropDialogClose = () => {
    setCropDialogOpen(false)
  }

  const handleCropDialogSave = async () => {
    if (croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(imagePreview || '', croppedAreaPixels, zoom)
        setImagePreview(croppedImage as string)
        setCropDialogOpen(false)
      } catch (error) {
        console.error("Error saving cropped image:", error)
      }
    } else {
      console.error("Cropped area pixels not set")
    }
  }

  return (
    <Card sx={{ maxWidth: 345, mx: "auto", mt: 4 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {t("profile.editProfile")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          <img
            src={imagePreview}
            alt="Profile Preview"
            style={{
              width: isSmallScreen ? 100 : 140,
              height: isSmallScreen ? 100 : 140,
              borderRadius: "50%",
            }}
          />
        </Box>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="profile-image-upload"
        />
        <label htmlFor="profile-image-upload">
          <Button variant="outlined" color="primary" component="span">
            {t("profile.uploadNewImage")}
          </Button>
        </label>
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.name")}
          name="name"
          value={updatedProfile?.name}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.email")}
          name="email"
          value={updatedProfile?.email}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.phoneNumber")}
          name="phoneNumber"
          value={updatedProfile?.phoneNumber}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.country")}
          name="country"
          value={updatedProfile?.country}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          label={t("profile.language")}
          name="language"
          value={updatedProfile?.language}
          onChange={handleChange}
        />
      </CardContent>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t("profile.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t("profile.save")}
        </Button>
      </Box>

      <Dialog open={cropDialogOpen} onClose={handleCropDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>{t("profile.cropImage")}</DialogTitle>
        <DialogContent>
          <Box sx={{ position: "relative", width: "100%", height: 400, backgroundColor: "#333" }}>
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCropDialogClose}>{t("profile.cancel")}</Button>
          <Button onClick={handleCropDialogSave} variant="contained" color="primary">
            {t("profile.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
})

export default EditProfile
