import * as React from "react"

import { Button, Icon, Typography } from "@mui/material"
import { auth, db } from "../firebase"
import { colors, typography } from "../theme"
import { doc, onSnapshot } from "firebase/firestore"

import LoginPage from "./Login"
import { PopupService } from "./PopupService"
import Tickets from "./Tickets"
import { formatCash } from "../utils"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useAuthState } from "react-firebase-hooks/auth"
import { useEffect } from "react"
// import { useNavigation } from "@react-navigation/native"
import { userStore } from "./stores/userStore"

export interface UsersCounterProps {
  style?: any
}

/**
 * Describe your component here
 */
export const UsersTickets = observer(function UsersCounter(props: UsersCounterProps) {
  const { style } = props
  const $styles = { ...$container, ...style }
  const { ticketsCount } = userStore
  const iconDefault: any = {
    name: "book_online",
    isFont: true,
    isFa5: true,
    style: { marginLeft: 1 },
    size: "medium",
    isUrl: false,
  }

  const [user] = useAuthState(auth)

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     return
  //   }
  //   const id: any = user?.uid

  //   const querySnapshot = doc(db, "users", id)
  //   return onSnapshot(querySnapshot, {
  //     next: (snap) => {
  //       setTicketsCount(snap?.data()?.points || 0)
  //     },
  //     error: (data) => {
  //       console.log("USERS-->", data)
  //     },
  //   })
  // }, [user])

  return (
    <Button
      variant="contained"
      style={$styles}
      className="flex flex-row gap-1/2 p-1"
      startIcon={
        <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
          {iconDefault.name}
        </Icon>
      }
      onClick={() => {
        !user || user?.isAnonymous
          ? PopupService.setPopup(<LoginPage />)
          : PopupService.setPopup(<Tickets />, "", {
              maxWidth: "100%",
              width: "100%",
              height: "100%",
            })
        // if (isAuthenticated) {
        //   navigation.navigate("Popup", { screen: "UserProfileScreen" })
        // } else {
        //   navigation.navigate("Login")
        // }
      }}
    >
      {formatCash(ticketsCount)}
      {/* <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
        {iconDefault.name}
      </Icon> */}
    </Button>
  )
})

const $container: any = {
  justifyContent: "center",
  flexDirection: "row",
}

const $text: any = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}
