import "react-phone-input-2/lib/style.css"

import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { User, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import { auth, db } from "../firebase"
import { collection, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore"

import { LoadingService } from "../LoadingService"
import PhoneInput from "react-phone-input-2"
import { PopupService } from "./PopupService"
import { UserLocation } from "./UserLocation"
import { useAuthState } from "react-firebase-hooks/auth"
import { useLocation } from "./LocationService"

async function addUser(user: User | null | undefined, data: any, password: string, close: any) {
  try {
    LoadingService.setLoading(true)

    const result = await createUserWithEmailAndPassword(auth, data.email, password)

    await sendEmailVerification(result.user)

    PopupService.pushPopup(
      "A verification email has been sent to your email address. Please check your inbox and follow the instructions to verify your email. If you do not see the email, please check your spam or junk folder.",
    )

    // Reference to the users collection
    const usersRef = collection(db, "users")

    // Generate a new document ID
    const newUserDocRef = doc(usersRef, result.user.uid)
    let snapshotEvent: any = null
    snapshotEvent = onSnapshot(newUserDocRef, {
      next: async (snap) => {
        data = {
          ...data,
          id: newUserDocRef.id,
        }

        if (snap.exists()) {
          await updateDoc(newUserDocRef, data)
          complete(snapshotEvent)
        }
      },
      error: (data) => {
        complete(snapshotEvent)
        LoadingService.setLoading(true, data?.message || data, true)
      },
      complete: () => {
        complete(snapshotEvent)
      },
    })

    // console.log(`User added with ID: ${newUserDocRef.id}`)
  } catch (e) {
    console.error("Error adding user: ", e)
  } finally {
    LoadingService.setLoading(false)
  }

  function complete(snapshotEvent: any) {
    LoadingService.setLoading(false)
    snapshotEvent && snapshotEvent()
    close && close()
  }
}

const RegistrationPage = (props: any) => {
  const { close } = props

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberFormatted, setPhoneNumberFormatted] = useState<string>("")
  const [country, setCountry] = useState("US")
  const [image, setImage] = useState(null)
  const [termsChecked, setTermsChecked] = useState(false)
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    terms: "",
  })
  const [user] = useAuthState(auth)

  const { availableLocations, location } = useLocation()

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleRegistration = async () => {
    let hasError = false
    const newErrors: any = {}

    if (!name) {
      newErrors.name = "Name is required"
      hasError = true
    }

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email format"
      hasError = true
    }

    if (password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long"
      hasError = true
    }

    // Validate phone number if needed
    // Add validation for other fields as required

    if (!termsChecked) {
      newErrors.terms = "Please accept the terms and conditions"
      hasError = true
    }

    if (hasError) {
      setErrors(newErrors)
      return
    }
    // return console.log("location----->", location)
    await addUser(
      user,
      {
        acceptedTerms: !!termsChecked,
        name,
        email,
        phoneNumber: phoneNumberFormatted,
        pushNotifications: true,
        userAddress: {
          city: "",
          county: "",
          disabled: false,
          houseNumber: 0,
          image: "",
          info: "",
          name: "",
          phone: "",
          postcode: "",
          province: "",
          street: "",
          ...(typeof location === "string" ? { country: location } : location),
        },
      },
      password,
      close,
    )
    console.log("Registration Successful")
  }

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Register
        </Typography>
        <TextField
          label="Name"
          variant="outlined"
          margin="normal"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Password"
          variant="outlined"
          margin="normal"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
        />
        <PhoneInput
          country={country}
          value={phoneNumber}
          onChange={(phone, data, e, formatted) => {
            setPhoneNumberFormatted(formatted)
            setPhoneNumber(phone)
          }}
          inputStyle={{ width: "100%", marginTop: "16px" }}
          containerStyle={{ width: "100%" }}
        />

        <div className="mt-5">
          <UserLocation color={"success"} center fullWidth />
        </div>
        {/* <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId="country-label"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        label="Country"
                    >
                        <MenuItem value="US">United States</MenuItem>
                        <MenuItem value="UK">United Kingdom</MenuItem>
                        
                    </Select>
                </FormControl> */}
        {/* Add image upload component */}
        {!!errors.terms && (
          <FormLabel error={true} color="error">
            {errors.terms}
          </FormLabel>
        )}
        <FormControlLabel
          control={
            <Checkbox checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
          }
          label="I accept the terms and conditions"
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleRegistration}
          sx={{ mt: 2 }}
        >
          Register
        </Button>
        <Box display="flex" justifyContent="center" width="100%" mt={2}>
          <Button
            color="primary"
            onClick={() => {
              PopupService.pushPopup(
                <iframe style={{ width: "100%", height: "100%" }} src="/privacy.html" />,
                null,
                { width: "100%", height: "100%" },
              )
            }}
          >
            Terms and Conditions
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default RegistrationPage
