import { auth, db as firestore } from "./firebase"
import { doc, getDoc } from "firebase/firestore"

import backend from "i18next-http-backend"
import detector from "i18next-browser-languagedetector"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { onAuthStateChanged } from "firebase/auth"

// import { reactI18nextModule } from "react-i18next"

const fetchFirestoreTranslations = async (lang: string) => {
  // const locales = {
  //     en: 'en',
  //     'en-US': 'en',
  //     'en-UK': 'en',
  //     es: 'es',
  //     'es-US': 'es',
  //     fr: 'fr'
  //   }
  const user = auth.currentUser
  if (!user) {
    return {}
  }

  const userDoc = await getDoc(doc(firestore, "app-configurations", "translations"))
  const userData = userDoc.data()

  return userData?.[lang] || {} 
}

i18n
  .use(detector)
  .use(backend)
  //   .use(reactI18nextModule)
  //   .use(Backend)
  .use(initReactI18next)
  .init({
    // lng: "en-US",
    debug: false,
    fallbackLng: (code) => {
      const baseLang = code?.split?.("-")?.[0] || code // Extract base language (e.g., 'en' from 'en-US')
      return [baseLang, code, "en"] // Fallback to base language and then to 'en'
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    load: "languageOnly",
    returnNull: false,
  })

onAuthStateChanged(auth, async (user) => {
  if (!user) {
    return
  }

  const currentLang = i18n.language?.split?.("-")?.[0] || "en"

  const firestoreTranslations = await fetchFirestoreTranslations(currentLang)
  if (Object.keys(firestoreTranslations).length > 0) {
    if (currentLang !== i18n.language) {
      i18n.addResourceBundle(i18n.language, "translation", firestoreTranslations, true, true)
    }
    i18n.addResourceBundle(currentLang, "translation", firestoreTranslations, true, true)
  }
})

export default i18n
