import { createContext, useContext, useState } from 'react';

import { Button } from '@mui/material';
import LoadingScreen from 'react-loading-screen';
import { LoadingService } from './LoadingService';
import { ModalPopup } from './components/PopupService';
import logo from './images/logo.png';

const LoadingContext = createContext(null);

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [okToDismiss, setOkToDismiss] = useState(false);

  LoadingService.setLoading = (loading, message, ok) => {
    setVisible(loading);
    setValue(message);
    setOkToDismiss(ok);
  };

  LoadingService.hide = () => {
    hideLoading();
  };

  LoadingService.show = (message) => {
    setValue(message);
    showLoading();
  };

  const showLoading = () => {
    setVisible(true);
  };

  const hideLoading = () => {
    setVisible(false);
  };

  return (
    <LoadingContext.Provider
      value={{ showLoading, hideLoading }}
    >
      {!visible ? null : <Loading
        message={value}
        visible={visible}
        setVisible={setVisible}
        setValue={setValue}
        setOkToDismiss={setOkToDismiss}
        okToDismiss={okToDismiss} />}
      {children}

    </LoadingContext.Provider>
  );
};

export function Loading(props) {
  const { children, message, setVisible, setValue, okToDismiss, setOkToDismiss, visible } = props;
  if (message) {
    return <ModalPopup
      modalVisible={visible}
      title={''}
      setModalVisible={setVisible}
      type="success"
      popup={message}
      showOk={okToDismiss}
    />;
  }
  return (
    <LoadingScreen
      loading={true}
      bgColor="#f1f1f180"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc={logo}
      text={"Loading"}
    >
      {children || <div></div>}
      {!!okToDismiss && <Button onClick={() => {
        setVisible(false);
        setValue("");
        setOkToDismiss(false);

      }}>OK</Button>}
    </LoadingScreen>
  );
}
