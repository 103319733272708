import { Button, Icon, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"

import InputMask from "react-input-mask"
import MaskUtils from "../utils/input-mask/mask"
import TextField from "@mui/material/TextField"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { colors } from "../theme"
import { observer } from "mobx-react-lite"
import { parse } from "../utils"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

export interface SupportInfoProps {
  style?: any
}

const mask = new MaskUtils({ mask: "+1 (999) 999-9999", maskChar: "_" })
export const SupportInfo = observer(function SupportInfo(props: SupportInfoProps) {
  const { style } = props
  const $styles = { ...$container, ...style }
  const [phoneMask, setPhoneMask] = useState("")
  const { supportInfo } = userStore
  const { t } = useTranslation()

  const info: any = supportInfo?.publicInfo?.app || {
    disabled: true,
  }

  function MaskFunc(): any {
    return () => <TextField variant="standard" />
  }
  //   useEffect(() => {
  //     if (phone) {
  //       mask('+1 ([000]) [000]-[0000]', phone, false).then(m => setPhoneMask(m));
  //     }
  //   }, [phone]);

  // console.log('SupportInfo----disabled------>',disabled)
  if (info.disabled) {
    return null
  }

  //   return <div>{"" + JSON.stringify(info)}</div>

  return (
    <div style={$styles}>
      <Button
        href={`whatsapp://send?text=&phone=${mask.formatValue(info.phone)}`}
        variant="text"
        color="inherit"
        onClick={() => null}
        // startIcon={""}
        className="flex flex-col justify-center"
      >
        <div className="flex flex-col justify-center">
          <div className="flex flex-row gap-1">
            <WhatsAppIcon style={{ color: colors.palette.secondary400 }} />
            <Typography className="text-sm font-bold">{mask.formatValue(info.phone)}</Typography>
           
          </div>

          <Typography className="text-xs text-center">{t(info.messageKey || info.message)}</Typography>
        </div>
      </Button>
    </div>
  )
})

const $container: any = {
  justifyContent: "center",
  margin: 10,
}
