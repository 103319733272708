import React, { useEffect, useState } from "react"

import { LoadingService } from "./LoadingService"
import { PopupService } from "./components/PopupService"
import { auth } from "./firebase"
import i18n from "./i18n"
import { signInAnonymously } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import { userStore } from "./components/stores/userStore"

async function loginUserAnonymously(user: any) {
  try {
    if (!user) {
      LoadingService.setLoading(true)
      signInAnonymously(auth)
    }
  } catch (error: any) {
    LoadingService.setLoading(true, error?.message || error, true)
  } finally {
    LoadingService.setLoading(false)
  }
}

export const AuthProvider = (props: any) => {
  const { children, partial } = props
  const [user, loading, error] = useAuthState(auth)
  const [canAccess, setCanAccess] = useState<boolean>(false)
  const { loadUser } = userStore

  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      try {
        LoadingService.setLoading(true)
        await i18n.init()
        setIsInitialized(true)
      } catch (error: any) {
        PopupService.pushPopup(error.message)
      } finally {
        LoadingService.setLoading(false)
      }
    }

    initialize()
  }, [])

  useEffect(() => {
    setCanAccess(false)

    if (loading) {
      return
    }

    if (!user) {
      loginUserAnonymously(user)
    } else {
      setCanAccess(true)
      if (!partial) {
        setCanAccess(!user.isAnonymous)
      }
      loadUser(user?.uid)
    }
  }, [loadUser, partial, user, loading])

  if (!canAccess) {
    return null
  }

  return !isInitialized ? null : children
}
